.modal-header {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.header-title {
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.modal-centralizado {
  position: absolute;
  left: 10;
  top: 50;
}