.consultar-usuarios__title {
    margin: auto;
    top: 50%;
    right: 50%;
    width: 100%;
    height: auto;
}

.consultarUsuarios-filter-btn {
    background-color: #5757B1 !important;
}