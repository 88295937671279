.cobranca-container {
    display: flex; 
    flex-direction: row; 
    padding: 20px 0px;
}

.cobranca-grid {
    display: flex;
    justify-content: center;
    align-content: center;
}

.cobranca-info {
    margin: 10px 0px;
}