.authentication {
    width: 100vw;
    height: 100vh;
    background-color: #F8F8F8;
}

.authentication__title {
    margin: 0;
    height: 25vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 56px;
    letter-spacing: 1px;
    background: linear-gradient(to right, #3E3EA9, #C5C5E5);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
}

.authentication__form {
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.authentication__form--title {
    padding-bottom: 20px;
    font-size: 30px !important;
}

.authentication__form--grid {
    display: flex;
    justify-content: center;
    align-items: center;
}

.authentication__form--button {
    width: 20%;
    margin-top: 15px !important;
    background-color: #7474C5 !important;
}

.authentication__form--button:hover, .authentication__form--button:active {
    background-color: #5757B1 !important;
}