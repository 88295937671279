.infomacoesConta__grid {
    margin: 2vh 0;
}

.informacoesConta__input {
    width: 100%;
}

.informacoesConta__grid--item {
    display: flex;
    justify-content: center;
}

.informacoesConta__input input {
    padding: 8.5px 14px;
}