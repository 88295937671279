.header {
  background-color: #5757B1;
}

.logo {
    max-width: 200px;
    max-height: 50px;
    width: auto;
    height: auto;
  }
  